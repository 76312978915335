import React, {useState, useEffect, useCallback } from "react";
import ReactDOM from 'react-dom';
import './index.css';
import '../node_modules/react-modal-video/scss/modal-video.scss';
// import App from './App';
import * as serviceWorker from './serviceWorker';

import Gallery from "react-photo-gallery";
import Carousel, {Modal, ModalGateway} from "react-images";
// import {photos} from "./photos";
// import ReactPlayer from "react-player";
import ModalVideo from 'react-modal-video'

import { Div, Img } from "react-images/lib/primitives";
import { className } from "react-images/lib/utils";
import { getSource } from "react-images/lib/components/component-helpers";


// https://github.com/jossmac/react-images/issues/300
function svgPlaceholder(width, height) {
    return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`;
}
export function CustomView(props) {
    const {
        data,
        formatters,
        getStyles,
        index,
        currentIndex,
        isFullscreen,
        isModal
    } = props;
    const overscan = 3;
    const inBounds = Math.abs(currentIndex - index) <= overscan;

    const innerProps = {
        alt: formatters.getAltText({ data, index }),
        src: inBounds
            ? getSource({ data, isFullscreen })
            : svgPlaceholder(data.width, data.height)
    };

    return (
        <Div
            css={getStyles("view", props)}
            className={className("view", { isFullscreen, isModal })}
        >
            <Img
                {...innerProps}
                className={className("view-image", { isFullscreen, isModal })}
                css={{
                    height: "auto",
                    maxHeight: "100vh",
                    maxWidth: "100vw",
                    userSelect: "none"
                }}
            />
        </Div>
    );
}

function App() {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);
    const [isOpen, setOpen] = useState(false)
    const [galleryPhotos, setGalleryPhotos] = useState([]);
    const [galleryThumbs, setGalleryThumbs] = useState([]);
    // const [isFullscreen, setFullscreen] = useState(false);

    const openLightbox = useCallback((event, {photo, index}) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    useEffect(() => {
        // const galId = window.location.pathname.substr(1);

        // const pageUrl = window.location.href;
        // const galId = pageUrl.substring(pageUrl.lastIndexOf('/')+1)

        const hash = window.location.hash;
        const galId = hash ? hash.substring(1) : null;
        console.log('galid: ' + galId);

        let reqUrl = new URL('https://sz89fdit6g.execute-api.ap-southeast-2.amazonaws.com/Prod/hello');
        const params = {galleryId:galId}
        Object.keys(params).forEach(key => reqUrl.searchParams.append(key, params[key]))

        const controller = new AbortController();
        const options = {
            method: 'GET',
            signal: controller.signal
        };
        const promise = fetch(reqUrl, options);
        promise
            .then(response => response.json())
            .then(data => {
                console.log("gallery retrieved");
                setGalleryPhotos(data['gallery_photos']);
                setGalleryThumbs(data['gallery_thumbs']);
                console.log(data);
                console.log(data['gallery_thumbs'][0]);
                // setGalleryPhotos(photos)
                // console.log(galleryPhotos)
            })
            .catch(error => {
                console.error('problem');
                console.error(error);
            });

    }, []);

    const executeScroll = () => {
        // window.scrollBy(0, window.innerHeight / 2);
        window.scrollTo({
            top: window.innerHeight,
            behavior: 'smooth'
        });
    }
    // const toggleFullscreen = (e) => {
    //     // console.log(e);
    //     // e.target.requestFullscreen();
    //     var elem = document.documentElement;
    //     if(isFullscreen)
    //         document.exitFullscreen();
    //     else
    //         elem.requestFullscreen();
    //
    //     setFullscreen(!isFullscreen);
    // }

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    return (
        <div>
            <div className='debug-vcenter'><h1>------</h1></div>

            {/*<div className='fullscreen-button' onClick={toggleFullscreen}></div>*/}

            <div className='header'>

                    <div></div>
                    <div>
                        <h1 className='desktop'>Tomaso + &#8239;Jessica</h1>
                        <h1 className='mobile'>Tomaso<br/>+<br/>Jessica</h1>
                    </div>
                    <div><p onClick={executeScroll}>View Gallery</p></div>
                    <div><p onClick={()=> setOpen(true)}>Watch Video</p></div>
                    <div></div>
            </div>

            <ModalVideo channel='vimeo' vimeo={{autoplay:false, quality:'1080p', byline:false, title:false}} isOpen={isOpen} videoId="435241024" onClose={() => setOpen(false)} />

            {galleryThumbs &&
                <Gallery photos={galleryThumbs} onClick={openLightbox}/>
            }
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            currentIndex={currentImage}
                            views={galleryPhotos.map(x => ({
                                ...x,
                                srcset: x.srcSet
                            }))}
                            components={{View: CustomView}}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
            <div className='footer'>
                <p>Photography • <a target='_blank' rel='noopener noreferrer' href="https://www.mirandastokkel.com/">Miranda Stokkel</a> </p>
                <p>Website • Jemaso ♡</p>
            </div>
        </div>
    );
}

ReactDOM.render(<App/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
